<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <FinishedBanner v-if="finished"></FinishedBanner>
          <CompletionGift
            v-if="showReward"
            :callback="showScoreRe"
            :callbackClose="callbackClose"
            :quantity="quantity"
          />
          <Score
            v-if="showScore"
            :difficulty="difficulty"
            :score="score"
          ></Score>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    FinishedBanner: () =>
      import(
        /* webpackChunkName: "component-mini-game-finished-banner" */ "@/components/miniGame/FinishedBanner"
      ),
    CompletionGift: () =>
      import(
        /* webpackChunkName: "component-game-loot" */ "@/components/miniGame/GameLoot"
      ),
    Score: () =>
      import(
        /* webpackChunkName: "component-mini-game-score" */ "@/components/miniGame/Score"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    miniGame: (state) => state.miniGame.data,
  }),
  props: [
    //
  ],
  data: () => ({
    showReward: false,
    finished: true,
    showScore: false,
    score: 0,
    difficulty: 1,
    quantity: 1,
  }),
  created() {
    //
  },
  mounted() {
    let count = 0;
    for (let index = 0; index < 3; index++) {
      let x = this.miniGame.currnet.round[index + 1];
      if (x.isCorrectAnswer) {
        count++;
      }
    }
    if (count > 2) {
      this.quantity = 4;
    } else if (count > 1) {
      this.quantity = 2;
    } else {
      this.quantity = 1;
    }
    setTimeout(() => {
      this.showReward = true;
    }, 1000);
  },
  methods: {
    showScoreRe() {
      //
    },
    callbackClose() {
      this.showReward = false;
      this.finished = false;
      let tempMiniGame;
      tempMiniGame = this.$_.cloneDeep(this.miniGame);
      tempMiniGame.data[tempMiniGame.currnet.gameid] = true;
      tempMiniGame.currnet = {};
      this.$store.commit("updateMiniGame", tempMiniGame);
      this.$router.push({
        name: "PageExploreMiniGames",
      });
      // this.showScore = true;
    },
    next() {
      this.$router.push({
        name: "PageExploreMiniGames",
      });
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>